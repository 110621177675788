import React, { useEffect, useRef, useState } from 'react'
import HeroSection from '../../components/hero-section/HeroSection'
import UnifiedTeam from '../../components/unified-team/UnifiedTeam'
import Footer from '../../components/footer/Footer'
import Products from '../../components/products/products'
import ContactNew from '../../components/contact-form/ContactNew'
import RealWorld from '../../components/real-world/RealWorld'
import Newnavigation from '../../components/new-navigation/new_navigation'
import FullSpectrum from '../../components/full-spectrum/Full_spectrum'
import HeaderSeo from '../../components/HeaderSeo/Headerseo'
import { HomeSeo } from '../../utils/Seo'
import { motion } from "framer-motion";
import HomeSolutions from '../../components/home-solutions/HomeSolutions'

const HomeNew = () => {
  
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  
const HomeRef = useRef()
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
    <div ref={HomeRef } className='homepage'> 
    <HeaderSeo data={HomeSeo}/>
      <Newnavigation isblack={true} routeName="Home"/>     
      <HeroSection   />
      <UnifiedTeam />
      <FullSpectrum/>
      {/* <FullTech /> */}
      <Products />
      <RealWorld/>
      {/* <HomeSolutions /> */}
      <ContactNew/>
      <Footer />
      </div>
      </motion.div>
  )
}

export default HomeNew