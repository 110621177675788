import React, { useEffect } from 'react'
import Newnavigation from '../../components/new-navigation/new_navigation'
import ContactNew from '../../components/contact-form/ContactNew'
import Footer from '../../components/footer/Footer'
import { motion } from 'framer-motion';
import CareerBanner from '../../components/CareerBanner/CareerBanner'
import ExploreLife from '../../components/Explore-life/ExploreLife'
import CareerPositions from '../../components/Career-positions/CareerPositions';
import { JobCard } from '../../components/job-card/job-card';
import { Container } from 'react-bootstrap';

const Careers = () => {

    useEffect(() => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    const jobs = [
       

    ]

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
        >
            <div>
                <Newnavigation routeName="solutions" />
                <CareerBanner />
                <ExploreLife />
                <Container>
                    <div>
                        <Container>
                            <h2 className='section_title'>Featured Positions</h2>
                        </Container>
                        <JobCard data={jobs} />
                    </div>
                </Container>
                <ContactNew />
                <Footer />
            </div>
        </motion.div>
    )
}

export default Careers