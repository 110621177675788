import { React, useState } from 'react';
import { Row, Col, NavLink, } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import ContactForm from '../contactus-popup/ContactForm';
import { CiLinkedin, CiTwitter, CiFacebook, CiInstagram } from 'react-icons/ci'
import { RiTwitterXLine } from "react-icons/ri";
import { Link } from 'react-router-dom';
import BlackLogo from '../black-logo/BlackLogo';
import './footer.css'

const Footer = () => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   return (
      <div id='contact'>
         <Container>

            <div className='footer_container'>
               <Row>
                  <Col sm='6' md={12} lg={4} xl={3} className='new_padding border_gray_right' >
                     <div className='footer_techno_icons'>
                        <Link to="/">
                           <BlackLogo />
                        </Link>
                        <div className='d-flex mt-5 footer_phonenumber'>

                           <h5>
                              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
                                 <path d="M14.6052 13.8961C14.7263 13.8155 14.8657 13.7663 15.0107 13.7532C15.1556 13.74 15.3016 13.7632 15.4353 13.8206L20.5934 16.1317C20.7673 16.206 20.9124 16.3346 21.007 16.4983C21.1016 16.6619 21.1407 16.8518 21.1184 17.0395C20.9485 18.3094 20.3232 19.4744 19.3587 20.3178C18.3942 21.1611 17.1562 21.6255 15.875 21.6245C11.9299 21.6245 8.14639 20.0574 5.35679 17.2678C2.56719 14.4782 1 10.6946 1 6.74955C0.999022 5.46833 1.4634 4.23037 2.30677 3.26588C3.15014 2.30139 4.31511 1.67604 5.585 1.50611C5.77273 1.48381 5.96262 1.5229 6.12627 1.61754C6.28992 1.71218 6.41852 1.85728 6.49281 2.03111L8.80391 7.19361C8.86069 7.3262 8.88381 7.47078 8.87122 7.61447C8.85863 7.75816 8.81071 7.89651 8.73172 8.0172L6.39438 10.7964C6.31147 10.9215 6.26244 11.066 6.25209 11.2157C6.24173 11.3655 6.27041 11.5153 6.33531 11.6506C7.23985 13.5024 9.15391 15.3935 11.0111 16.2892C11.1471 16.3539 11.2977 16.3819 11.4479 16.3706C11.598 16.3593 11.7427 16.309 11.8675 16.2247L14.6052 13.8961Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                           </h5>


                           <div className='footer_conatct_number'>
                              <h3> <a rel="noreferrer" href="tel:+9196262 65444">+91 - 96262 65444</a></h3>
                              {/* <h3><a rel="noreferrer" href="tel: +9196262 67775"> +91 - 96262 67775</a></h3> */}
                           </div>
                        </div>
                        <div className='d-flex footer_phonenumber'>

                           <h5>
                              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
                                 <path d="M1 8.52734V19.9023C1 20.1344 1.09219 20.357 1.25628 20.5211C1.42038 20.6852 1.64294 20.7773 1.875 20.7773H21.125C21.3571 20.7773 21.5796 20.6852 21.7437 20.5211C21.9078 20.357 22 20.1344 22 19.9023V8.52734L11.5 1.52734L1 8.52734Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                 <path d="M22 8.52734L13.4097 14.6523H9.59141L1 8.52734" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                           </h5>

                           <div className='footer_email_info'>
                              <h3> <a href="mailto:info@technogenesis.in">info@technogenesis.in</a> </h3>
                           </div>
                        </div>
                        <div className='d-flex footer_phonenumber'>
                           <div>
                              <h5>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" viewBox="0 0 20 25" fill="none">
                                    <path d="M9.75 12.25C11.683 12.25 13.25 10.683 13.25 8.75C13.25 6.817 11.683 5.25 9.75 5.25C7.817 5.25 6.25 6.817 6.25 8.75C6.25 10.683 7.817 12.25 9.75 12.25Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M18.5 9.75C18.5 17.625 9.75 23.75 9.75 23.75C9.75 23.75 1 17.625 1 9.75C1 7.42936 1.92187 5.20376 3.56282 3.56282C5.20376 1.92187 7.42936 1 9.75 1C12.0706 1 14.2962 1.92187 15.9372 3.56282C17.5781 5.20376 18.5 7.42936 18.5 9.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                 </svg>
                              </h5>
                           </div>
                           <div className='footer_conatct_number location'>
                              {/* <h3> 1642, A cross, 4th A Main, Kalyan Nagar, Nagarbhavi Main Road, Bangalore 560043  </h3> */}
                              <h3>4B, ELCOT IT Building, ELCOSEZ, Vadapalanji, Madurai - 625021.</h3>
                           </div>
                        </div>
                      
                     </div>
                  </Col>
                  <Col sm='6' md={6} lg={4} xl={2} className='new_padding_2 border_gray_right' >
                     <div className='footer_conatct'>
                        <h5>Company</h5>
                     </div>
                     <div className='links'>
                        <p>
                           <Link to='/about-us'>About</Link>
                        </p>
                        <p onClick={handleShow} style={{ cursor: 'pointer' }}>
                           <Link >Contact</Link>
                        </p>
                        <p>
                           <Link to='/Careers'>Careers</Link>
                        </p>

                       
                        <p>
                           <Link to='/privacy-policy'>Privacy Policy</Link>
                        </p>
                        <p>
                           <Link to='/terms-of-service'>Terms</Link>
                        </p>



                     </div>

                  </Col>
                  <Col sm='6' md={6} lg={4} xl={2} className='new_padding_2 border_gray_right' >
                     <div className='footer_conatct'>
                        <h5>Services</h5>
                     </div>
                     <div className='links'>
                        <p>
                           <Link to='/services'>Consulting</Link>
                        </p>
                        <p>
                           <Link to='/services'>Technology</Link>
                        </p>
                        <p>
                           <Link to='/services'>Outsourcing</Link>
                        </p>

                     </div>

                  </Col>
                  <Col sm='6' md={6} lg={4} xl={2} className=' new_padding_2 border_gray_right'  >
                     <div className='footer_conatct'>
                        <h5>Industries</h5>
                        <div className='links'>
                           <p><Link to='/industry/healthcare'>Healthcare</Link></p>
                           <p><Link to='/industry/logistics'>Retail Services</Link></p>
                           <p><Link to='/industry/fintech'>Fintech</Link></p>
                           <p><Link to='/industry/travel&hospitality' >Travel & Hospitality</Link></p>
                           <p><Link to='/industry/education' >Education</Link></p>
                           <p><Link to='/industry/insurance'>Insurance</Link></p>
                           <p><Link to='/industry/blockchain'>Blockchain</Link></p>
                        </div>
                     </div>
                  </Col>
                  <Col sm='6' md={6} lg={4} xl={3} className=' new_padding_2'  >
                     <div className='footer_conatct'>
                        <h5>Solutions</h5>
                        <div className='links'>
                           <p><Link to='/solutions/retail'>Retail & e-commerce</Link></p>
                           <p><Link to='/solutions/edutech'>Edutech</Link></p>
                           <p><Link to='/solutions/transportation'>Transportation</Link></p>
                           <p><Link to='/solutions/healthcare-pharmaceutical'>Healthcare & Pharmaceutical</Link></p>

                        </div>
                     </div>
                  </Col>


               </Row>

            </div>






            {/* <div className='footer_container'>
          <Row>
             <Col lg={5} md={6}  className=' adjust_width_50 border_gray_right' >
             <Row>
                <Col lg={6} className='new_padding' >
                <div className='footer_techno_icons'>
                   <a href="#home">
                   <Logo />
                   </a>
                   <div className='d-flex footer_social'>
                      <h4>
                         <a target='_blank'   rel="noreferrer"  href="https://www.linkedin.com/company/technogenesismadurai/">
                            <CiLinkedin />
                         </a>
                      </h4>
                      <h4>
                         <a target='_blank'   rel="noreferrer"   href="https://twitter.com/i/flow/login?redirect_after_login=%2FTechnoGenesis16">
                            <CiTwitter />
                         </a>
                      </h4>
                      <h4>
                         <a target='_blank'   rel="noreferrer"   href="https://www.facebook.com/technogenesis/">
                            <CiFacebook />
                         </a>
                      </h4>
                   </div>
                </div>
                </Col>
                <Col lg={6}  className='new_padding ' >
                <div className='footer_conatct'>
                   <h5>Quick Links</h5>
                   </div>
                   <div className='links'>
                     <p>
                        <Link to='digital'>Digital</Link>
                     </p>
                     <p>
                        <Link to='expertise'>Expertise</Link>
                     </p>
                     <p>
                        <Link to='about'>About</Link>
                     </p>
                     <p>
                        <Link to='careers'>Careers</Link>
                     </p>
                    



                   </div>
             
                </Col>
             </Row>
             </Col>
             <Col lg={7} md={6}  className=' adjust_width_50 '>
             <Row className='h-100'>
                
                <Col lg={6} className='border_gray_right new_padding_2 '  >
                <div className='footer_conatct'>
                   <h5>Get in touch</h5>
                   <div className='d-flex footer_phonenumber'>
                      <div>
                        <h5>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
<path d="M14.9802 13.8966C15.1013 13.816 15.2407 13.7668 15.3857 13.7537C15.5306 13.7405 15.6766 13.7637 15.8103 13.8211L20.9684 16.1322C21.1423 16.2065 21.2874 16.3351 21.382 16.4988C21.4766 16.6624 21.5157 16.8523 21.4934 17.04C21.3235 18.3099 20.6982 19.4749 19.7337 20.3183C18.7692 21.1616 17.5312 21.626 16.25 21.625C12.3049 21.625 8.52139 20.0579 5.73179 17.2682C2.94219 14.4786 1.375 10.6951 1.375 6.75004C1.37402 5.46882 1.8384 4.23086 2.68177 3.26637C3.52514 2.30188 4.69011 1.67653 5.96 1.5066C6.14773 1.4843 6.33762 1.52339 6.50127 1.61803C6.66492 1.71267 6.79352 1.85777 6.86781 2.0316L9.17891 7.1941C9.23569 7.32669 9.25881 7.47127 9.24622 7.61496C9.23363 7.75865 9.18571 7.897 9.10672 8.01769L6.76938 10.7969C6.68647 10.922 6.63744 11.0665 6.62709 11.2162C6.61673 11.366 6.64541 11.5158 6.71031 11.6511C7.61485 13.5028 9.52891 15.3939 11.3861 16.2897C11.5221 16.3543 11.6727 16.3824 11.8229 16.3711C11.973 16.3597 12.1177 16.3095 12.2425 16.2252L14.9802 13.8966Z" stroke="#333333" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                        </h5>
                        
                      </div>
                      <div className='footer_conatct_number'>
                         <h3> <a target='_blank'  rel="noreferrer"   href="tel:+9196262 65444">+91 - 96262 65444</a></h3>
                         <h3><a target='_blank'  rel="noreferrer"   href="tel: +9196262 67775"> +91 - 96262 67775</a></h3>
                      </div>
                   </div>
                   <div className='d-flex footer_email'>
                      <div>
                         <h5>
                         <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
<g clip-path="url(#clip0_388_242)">
<path d="M3.5 10.5269V21.9019C3.5 22.1339 3.59219 22.3565 3.75628 22.5206C3.92038 22.6847 4.14294 22.7769 4.375 22.7769H23.625C23.8571 22.7769 24.0796 22.6847 24.2437 22.5206C24.4078 22.3565 24.5 22.1339 24.5 21.9019V10.5269L14 3.52686L3.5 10.5269Z" stroke="#333333" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.5 10.5269L15.9097 16.6519H12.0914L3.5 10.5269" stroke="#333333" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_388_242">
<rect width="28" height="28" fill="white" transform="translate(0 0.0268555)"/>
</clipPath>
</defs>
</svg>
                         </h5>
                      </div>
                      <div className='footer_email_info'>
                         <h3> <a target='_blank'  rel="noreferrer"   href="mailto:+9196262 65444">info@technogenesis.in</a> </h3>
                      </div>
                   </div>
                </div>
                </Col>
                <Col lg={6} className='new_padding_3' >
                <h5>Locations</h5>
                <div className='footer_conatct'>
                   <div className='d-flex footer_phonenumber'>
                      <div>
                         <h5>
                            <SlLocationPin />
                         </h5>
                      </div>
                      <div className='footer_conatct_number location'>
                         <h3> 1642, A cross, 4th A Main, Kalyan Nagar, Nagarbhavi Main Road, Bangalore 560043  </h3>
                      </div>
                   </div>
                </div>
                <div className='footer_conatct'>
                   <div className='d-flex footer_phonenumber location'>
                      <div>
                         <h5>
                            <SlLocationPin />
                         </h5>
                      </div>
                      <div className='footer_conatct_number'>
                         <h3>
                            50/5, 3rd Floor, MMS Complex, Near to Vinayakar Temple, Vasantha Nagar, Madurai - 625003
                         </h3>
                      </div>
                   </div>
                </div>
                </Col>
             </Row>
             </Col>
          </Row>
       </div> */}
            <div className='copy_rights'>
               <p>© Copyright Techno Genesis Software Solutions Private Limited 2024. All Rights Reserved.</p>
               <div className='d-flex footer_social'>
                           <h4>
                              <a target='_blank' rel="noreferrer" href="https://www.linkedin.com/company/technogenesismadurai/">
                                 <CiLinkedin />
                              </a>
                           </h4>

                           {/* <h4>
                              <a target='_blank' rel="noreferrer" href="https://www.instagram.com/technogenesis2016/">
                                 <CiInstagram />
                              </a>
                           </h4> */}

                           <h4>
                              <a target='_blank' rel="noreferrer" href="https://twitter.com/i/flow/login?redirect_after_login=%2FTechnoGenesis16">
                              <RiTwitterXLine fontSize={22} />
                              </a>
                           </h4>
                           <h4>
                              <a target='_blank' rel="noreferrer" href="https://www.facebook.com/technogenesis/">
                                 <CiFacebook />
                              </a>
                           </h4>
                        </div>
            </div>
         </Container>
         <ContactForm show={show} onClose={handleClose} onShow={handleShow} />
      </div>
   )
}

export default Footer